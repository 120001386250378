import React from 'react'
import styled from 'styled-components'

import { Box, Flex } from 'ui/atoms'
import { LayoutMobile } from 'ui/layout'
import { config } from 'config'
import { TextTitleRaffleWinner } from './TextTitleRaffleWinner'
import { RaffleWinnerItemText } from './RaffleWinnerItemText'
import { raffleWinnerItemImgProps } from './raffleWinnerItemImgProps'

const RaffleWinnerItemImg = styled.img`
  width: 100%;
  margin: 0 auto;
`

const RaffleWinnerItem = ({ img, text }) => {
  return (
    <Flex flexDirection="column" alignItems="center" mb="20px">
      <RaffleWinnerItemText>{text}</RaffleWinnerItemText>
      <Box borderRadius="10px" overflow="hidden" maxWidth="700px">
        <RaffleWinnerItemImg src={img} alt="" />
      </Box>
    </Flex>
  )
}

export const RaffleWinnersMobile = React.forwardRef(
  ({ tickets, isLoading, hasError, onRetry, isWideViewport }, ref) => {
    return (
      <LayoutMobile
        ref={ref}
        {...config.backgrounds.RAFFLE_WINNERS_MOBILE_BACKGROUND}
        isRaffleWinnersPage={true}
      >
        <LayoutMobile.Header mb="30px">
          <TextTitleRaffleWinner>Победители</TextTitleRaffleWinner>
        </LayoutMobile.Header>

        <LayoutMobile.Content isRaffleWinnersPage={true}>
          <RaffleWinnerItem
            img="/img/newYearGame2024_2025/raffle_winners_from_2024-11-25_to_2024-12-01.jpg"
            text="25 ноября 2024 - 1 декабря 2024"
          />
          <RaffleWinnerItem
            img="/img/newYearGame2024_2025/raffle_winners_from_2024-12-02_to_2024-12-08.jpg"
            text="2 декабря 2024 - 8 декабря 2024"
          />
          <RaffleWinnerItem
            img="/img/newYearGame2024_2025/raffle_winners_from_2024-12-09_to_2024-12-15.jpg"
            text="9 декабря 2024 - 15 декабря 2024"
          />
          <RaffleWinnerItem
            img="/img/newYearGame2024_2025/raffle_winners_from_2024-12-16_to_2024-12-22.jpg"
            text="16 декабря 2024 - 22 декабря 2024"
          />
          <RaffleWinnerItem
            img="/img/newYearGame2024_2025/raffle_winners_from_2024-12-23_to_2024-12-29.jpg"
            text="23 декабря 2024 - 29 декабря 2024"
          />
          <RaffleWinnerItem
            img="/img/newYearGame2024_2025/raffle_winners_from_2024-12-30_to_2025-01-05.jpg"
            text="30 декабря 2024 - 5 января 2025"
          />
          <RaffleWinnerItem
            img="/img/newYearGame2024_2025/raffle_winners_from_2025-01-06_to_2025-01-12.jpg"
            text="6 января 2025 - 12 января 2025"
          />
        </LayoutMobile.Content>
      </LayoutMobile>
    )
  }
)
