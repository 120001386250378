import styled from 'styled-components'

import { TextTitle } from 'ui/atoms'

export const RaffleWinnerItemTextNewYearGame2024_2025 = styled(TextTitle)`
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
  text-transform: none;
`
