import { Link } from 'react-router-dom'

import { PATHS } from 'routing/paths'
import { Box, Text } from 'ui/atoms'
import { mainMenuWrapperProps } from './mainMenuWrapperProps'

const MainMenuLinkContent = ({ children }) => (
  <Text
    href="#"
    fontWeight="700"
    fontSize="18px"
    lineHeight="24px"
    textTransform="uppercase"
    color={{ _: '#fff', hover: 'red.4' }}
    style={{ transition: 'color 0.3s ease' }}
  >
    {children}
  </Text>
)

export const MainMenu = props => (
  <Box {...mainMenuWrapperProps} {...props}>
    <Box as="li" px="24px">
      <Link to={PATHS.RULES}>
        <MainMenuLinkContent>Правила</MainMenuLinkContent>
      </Link>
    </Box>
    <Box as="li" px="24px">
      <a href="/img/newYearGame2024_2025/raffle_winners_from_2025-01-06_to_2025-01-12.jpg">
        <MainMenuLinkContent>Победители</MainMenuLinkContent>
      </a>
    </Box>
    <Box as="li" px="24px">
      <a href="/img/sponsor_goods_desktop.jpg">
        <MainMenuLinkContent>Спонсоры</MainMenuLinkContent>
      </a>
    </Box>
  </Box>
)
